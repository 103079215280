<template lang="html">
  <div
    v-if="canAccessPage">

    <usuario-funcionario-tabela
      class="CRUDGenerico__ContentTable"
      ref="usuarioFuncionarioTabela"
      :title="$tc('label.usuario_interno', 2)"
      :table-rows="tableRows"
      :registros="tableRows"
      :limite-pagina="limitePagina"
      :paginas="paginas"
      :carregando="carregando"
      :table-header="[]"
      :visible-columns="[]"
      :mobile-invisible-columns="[]"
      :ignore-columns="[]"
      @USUARIOTABELA_EDIT="editUsuario"
      @USUARIOTABELA_VER="verUsuario"
      @TABELA_BUSCAR_PROXIMA_PAGINA="requestNextPage"
      @TABELA_FILTRAR_PAGINA="filtrar"
      @TABELAGENERICA_ORDENAR="organizar"
      @USUARIOTABELA_RESET_SENHA="openDialogResetSenha"
      @USUARIOTABELA_RESET_SENHA_PADRAO_TRADELINKS="openDialogResetSenhaPadraoTradelinks"
      @USUARIOTABELA_CONFIGURACAO="openDialogConfig"
      @PAGINACAO_ALTERANDO_TAMANHO_PAGINA="alterarTamanhoPagina"/>

    <v-tooltip left v-if="cadastrarGestaoFuncionario && canPost && !indUsuarioLogadoFornecedor">
      <template v-slot:activator="{ on }">
        <v-btn id="btn_novo"
          fab
          @click="$router.push({ name: 'novoUsuarioFuncionario' })"
          class="v-btn__fab"
          color="primary"
          v-on="on"
          bottom
          right
          fixed>
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>{{$t('label.novo_usuario')}}</span>
    </v-tooltip>

    <v-dialog v-model="dialogResetSenha" width="350">
      <v-card>
        <v-card-title>
          {{ $t('label.redefinir_senha') }}
        </v-card-title>
        <v-card-text>
          {{ $t('message.deseja_redefinir_senha') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogResetSenha = false">
            {{ $t('label.nao') }}
          </v-btn>
          <v-btn color="primary" text @click="redefinirSenha()">
            {{ $t('label.sim') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogResetSenhaPadraoTradelinks" width="350">
      <v-card>
        <v-card-title>
          {{ $t('label.redefinir_senha_padrao') }}
        </v-card-title>
        <v-card-text>
          {{ $t('message.deseja_redefinir_senha_padrao', {senhaPadraoTradelinks: senhaPadraoTradelinks}) }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialogResetSenhaPadraoTradelinks()">
            {{ $t('label.nao') }}
          </v-btn>
          <v-btn color="primary" text @click="redefinirSenhaPadraoTradelinks()">
            {{ $t('label.sim') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUsuarioConfiguracao" width="350">
      <v-card>
        <v-card-title>
          {{ $tc('label.configuracao', 1) }} {{ $tc('title.senha', 1) }}:
        </v-card-title>
        <v-card-text>
          <div>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    id="senha_temporaria"
                    name="senha_temporaria"
                    required
                    tabindex="1"
                    v-model="config.valor"
                    :label="`${$t('configuracao.SENHA_TEMPORARIA')} *`"
                    @blur="requestAtualizarConfig(config)">
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialogConfig">
            {{ $tc('label.fechar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import CRUDGenerico from '../../shared-components/generico/CRUDGenerico';
import UsuarioFuncionarioTabela from './UsuarioFuncionarioTabela';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { copyObject } from '../../common/functions/helpers';

export default {
  name: 'UsuarioFuncionarioList',
  extends: CRUDGenerico,
  data() {
    return {
      usuarioResources: this.$api.usuario(this.$resource),
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      tableRows: [],
      dialogResetSenha: false,
      dialogResetSenhaPadraoTradelinks: false,
      dialogUsuarioConfiguracao: false,
      carregando: false,
      paginas: 0,
      limitePagina: 10,
      senhaTemporaria: null,
      senhaPadraoTradelinks: null,
      usuario: {
        representante: {
          dependencias: {
            canaisVenda: [],
          },
        },
        superioresImediatos: [],
      },
      config: {},
    };
  },
  computed: {
    ...generateComputed('usr', [
      'canAccessPage',
      'canPost',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    indUsuarioLogadoFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    cadastrarGestaoFuncionario() {
      if (!this.getAllRoles) {
        return false;
      }

      return !!this.getAllRoles
        .filter((el) => el === 'USR_CADASTRAR_GESTAO_DE_FUNCIONARIO').length;
    },
    consutarGestaoFuncionario() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'USR_CONSULTAR_GESTAO_DE_FUNCIONARIO').length;
    },
  },
  components: {
    UsuarioFuncionarioTabela,
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
      }
    },
    alterarTamanhoPagina(valor) {
      this.limitePagina = valor;
      this.requestPopular({ size: valor, page: this.$refs.usuarioFuncionarioTabela.indexPagina + 1 });
    },
    filtrar(pagination) {
      this.searchQuery = pagination.filter;
      pagination.page = this.$refs.usuarioFuncionarioTabela.indexPagina + 1;
      this.requestPopular(pagination);
    },
    requestNextPage(pagina) {
      const pagination = {
        size: this.limitePagina,
        page: pagina,
        filter: this.searchQuery,
      };
      this.requestPopular(pagination);
    },
    requestPopular(page = null) {
      if (page && page.filter) {
        page.nome = page.filter;
      }
      this.request(this.usuarioResources.listar, page);
    },
    request(requisicao, page = null, cb = null) {
      const pagination = page || { size: 10, page: 1 };
      this.carregando = true;

      requisicao(pagination)
        .then((response) => {
          this.tableRows = response.data.resposta;
          const qtdeRegistros = response.data.quantidadeRegistrosPagina;
          this.paginas = Math.ceil(qtdeRegistros / pagination.size);

          this.carregando = false;
          if (cb) {
            cb();
          }
        })
        .catch((err) => {
          this.$error(this, err);
          this.carregando = false;
        });
    },
    editUsuario(item) {
      this.$router.push({ name: 'editarUsuarioFuncionario', params: { id: item.id, ind_possui_anexos: item.indPossuiAnexos } });
    },
    verUsuario(item) {
      this.$router.push({ name: 'verUsuarioFuncionario', params: { id: item.id } });
    },
    viewItem(item) {
      this.$router.push({ name: 'verUsuarioFuncionario', params: { id: item.id } });
    },
    openDialogResetSenha(row) {
      this.usuario = copyObject(row);
      this.dialogResetSenha = true;
    },
    openDialogResetSenhaPadraoTradelinks(row) {
      this.usuario = copyObject(row);
      this.dialogResetSenhaPadraoTradelinks = true;
    },
    openDialogConfig() {
      this.senhaTemporaria = this.config.valor;
      this.dialogUsuarioConfiguracao = true;
    },
    openDialog(ref) {
      this.$refs[ref].open();
    },
    closeDialogConfig() {
      if (!this.config.valor) {
        return;
      }
      this.dialogUsuarioConfiguracao = false;
    },
    redefinirSenha() {
      this.dialogResetSenha = false;
      const idUsuario = this.usuario.id;
      this.usuarioResources.redefinirSenha({ idUsuario }, { idUsuario })
        .then(() => {
          this.$toast(this.$t('message.senha_redefinida'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    redefinirSenhaPadraoTradelinks() {
      this.dialogResetSenhaPadraoTradelinks = false;
      const idUsuario = this.usuario.id;
      this.usuarioResources.redefinirSenhaPadraoTradelinks({ idUsuario }, { idUsuario })
        .then(() => {
          this.$toast(this.$t('message.senha_redefinida'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    requestPopularConfig() {
      this.usuarioResources.listarConfiguracao()
        .then((response) => {
          this.config = response.data;
        });
    },
    requestAtualizarConfig(config) {
      if (!config.valor) {
        this.$toast(this.$t('message.campo_obrigatorio'));
        this.requestPopularConfig();
        this.openDialogConfig();
        return;
      }
      if (this.senhaTemporaria !== config.valor) {
        this.usuarioResources.atualizarConfiguracao(config)
          .then(() => {
            this.$toast(this.$t('message.atualizado_confira_tabela'));
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    closeDialogResetSenhaPadraoTradelinks() {
      this.dialogResetSenhaPadraoTradelinks = false;
    },
    buscarChaveSenhaPadraoTradelinks() {
      this.configSistemaResource.buscarChave({ chave: 'SENHA_TEMPORARIA_AMBIENTE_TESTE' })
        .then((resp) => {
          this.senhaPadraoTradelinks = resp.bodyText;
        });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }

    this.requestPopularConfig();
    // this.requestPopular();
    this.buscarChaveSenhaPadraoTradelinks();
  },
};
</script>
